export enum ORDER_REQUIREMENTS__OPTION_TYPE {
  GOOD_TILL_CANCELED = 1,
  FILL_OR_KILL,
  IMMEDIATE_OR_CANCEL,
  POST_ONLY
}

export enum CONDITIONAL_OPTIONS_TYPE {
  BID = 1,
  ASK,
  LAST
}
